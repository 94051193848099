<template>
  <div class="colors">
    <table v-if="colors.length" class="colors__table">
      <tr v-for="item in colors" :key="item.id">
        <td>
          <h2>{{ item.name }}</h2>
        </td>
        <td>
          <router-link :to="`/color/${item.id}`">
            <img src="../../../assets/edit.svg" />
          </router-link>
        </td>
        <td>
          <router-link :to="`/color/delete/${item.id}`">
            <img src="../../../assets/close.svg" />
          </router-link>
        </td>
      </tr>
    </table>
    <div v-else>Content loading</div>

    <router-link class="colors__add button-submit register-btn" to="/color/register">
      + {{ $t('colors.add') }}
    </router-link>
  </div>
</template>

<script>
import { api } from '@/api';

export default {
  name: 'Colors',
  data() {
    return {
      colors: [],
      message: ''
    };
  },
  mounted() {
    api
      .get('/colors')
      .then((res) => {
        this.colors = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  }
};
</script>

<style lang="scss">
.colors {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;

  &__table {
    width: 100%;
    margin-bottom: 30px;
    tr {
      display: grid;
      column-gap: 40px;
      align-items: center;
      grid-template-columns: 1fr 30px 30px;
    }
  }

  &__add {
    box-sizing: border-box;
    color: var(--white);
  }
}
</style>
