<template>
  <form class="auth-form__body" @submit.prevent="login">
    <img alt="logo" src="../../assets/logo.svg" />
    <br />
    <h3 class="auth-form__title">Authorization</h3>
    <br />
    <div class="auth-form__input-container">
      <text-input
        id="login"
        class="auth-form__input"
        :width="250"
        :value="username"
        type="login"
        name="email"
        @change="changeEmail($event)"
      />
    </div>
    <br />
    <div class="auth-form__input-container">
      <text-input
        id="password"
        class="auth-form__input"
        :width="250"
        :value="password"
        type="password"
        name="password"
        @change="changePassword($event)"
      />
    </div>
    <br />
    <div class="auth-form__additional">
      <button class="auth-form__recover-passw" @click="forgotPassword()">Forgot password</button>
      <button class="button-submit auth-form__submit" type="submit">Submit</button>
    </div>
    <div v-if="showDomainSwitcher" class="auth-form__input-container">
      <br />
      <br />
      <br />
      <br />
      <drop-down-select
        name="domain"
        :width="250"
        :value="configuratorDomain"
        :options="configuratorDomains"
        @change="changeConfiguratorDomain($event)"
      >
        <template v-slot:default="props">
          <div>{{ props.option.name }}</div>
        </template>
      </drop-down-select>
    </div>
    <div class="error">{{ auth.commit }}</div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/text-input/TextInput';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import { api } from '@/api';
import { CONSTRUCTOR_URL, LIBRARY_URL } from '@/config';

export default {
  name: 'Login',
  components: {
    TextInput,
    DropDownSelect
  },
  data() {
    let pf = location.host.split('.');
    let showDomainSwitcher =
      (pf.length > 2 && pf[2] === 'test' && pf[1] !== 'ru') || pf[pf.length - 1] !== 'ru';
    if (pf.length > 2 && pf[2] === 'test') {
      pf[1] = 'xx';
    } else {
      pf[pf.length - 1] = 'xx';
    }
    pf[0] = 'services';
    let prefix = pf.join('.');

    return {
      username: '',
      password: '',
      host: location.host,
      fullPage: true,
      showDomainSwitcher: showDomainSwitcher,
      configuratorDomain: location.host,
      configuratorDomains: [
        {
          value: prefix.replace('.xx', '.eu'),
          name: 'Europe'
        },
        {
          value: prefix.replace('.xx', '.com'),
          name: 'United States'
        }
      ]
    };
  },
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    forgotPassword() {
      this.$router.push(`/forgot-password`);
    },
    changeEmail(value) {
      this.username = value;
    },
    changePassword(value) {
      this.password = value;
    },
    changeConfiguratorDomain(value) {
      this.configuratorDomain = value;
      let url = '//' + value + '/auth';
      window.location.href = url;
      document.body.innerHTML =
        '<meta http-equiv="refresh" content="5; URL="' +
        url +
        '"><script>location.href = "' +
        url +
        '";<\/script>';
    },
    login() {
      this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password
      });
    }
  }
};
</script>
