<template>
  <form class="auth-form__body" @submit.prevent="sendEmail">
    <img alt="logo" src="../../assets/logo.svg" />
    <br />
    <h3 class="auth-form__title">Forgot Password</h3>
    <br />
    <div class="auth-form__input-container">
      <text-input
        id="login"
        class="auth-form__input"
        :width="250"
        :value="username"
        type="login"
        name="email to request password"
        @change="changeEmail($event)"
      />
    </div>
    <br />
    <div class="auth-form__additional">
      <button class="button-submit auth-form__submit" type="submit">Submit</button>
    </div>
    <div class="error">{{ auth.commit }}</div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/text-input/TextInput';
import { api } from '@/api';
import { CONSTRUCTOR_URL, LIBRARY_URL } from '@/config';

export default {
  name: 'ForgotPassword',
  components: {
    TextInput
  },
  data() {
    let pf = location.host.split('.');
    let showDomainSwitcher =
      (pf.length > 2 && pf[2] === 'test' && pf[1] !== 'ru') || pf[pf.length - 1] !== 'ru';
    if (pf.length > 2 && pf[2] === 'test') {
      pf[1] = 'xx';
    } else {
      pf[pf.length - 1] = 'xx';
    }
    pf[0] = 'services';
    let prefix = pf.join('.');

    return {
      username: '',
      host: location.host,
      fullPage: true,
      showDomainSwitcher: showDomainSwitcher
    };
  },
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    changeEmail(value) {
      this.username = value;
    },
    sendEmail() {
      this.$store.dispatch('auth/sendEmail', {
        email: this.username
      });
    }
  }
};
</script>
