<template>
  <div class="input__container" :style="{ width: width + 'px' }">
    <span v-show="name" class="input__label">
      {{ name }}
    </span>
    <input
      ref="input"
      class="input__value"
      :class="{ disabled: disabled }"
      type="number"
      :placeholder="placeholder"
      :step="step"
      :min="min"
      :max="max"
      :value="value"
      @change="debounce ? dChange($event) : change($event)"
    />
    <div v-if="isRemoved" class="input__del" @click="remove()">
      <img src="../../../assets/close.svg" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash-es/debounce';

export default {
  name: 'NumberInput',
  props: {
    width: {
      type: Number
    },
    name: {
      default: '',
      type: String,
      required: false
    },
    placeholder: {
      type: String
    },
    value: {
      default: null
    },
    disabled: Boolean,
    isRemoved: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1,
      required: false
    },
    min: {
      type: Number,
      default: undefined,
      required: false
    },
    max: {
      required: false,
      type: Number,
      default: undefined
    },
    debounce: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    change(e) {
      let value = Number(e.target.value);
      const { min, max } = this;
      if (min !== undefined && value < min) {
        value = min;
      } else if (max !== undefined && value > max) {
        value = max;
      }

      if (this.value !== value) {
        this.$emit('change', value);
      }
      this.$forceUpdate();
    },
    // eslint-disable-next-line func-names
    dChange: debounce(function (e) {
      this.change(e);
    }, 300),
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>
<style lang="scss">
.input {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100px;
    // border: 1px solid var(--light-gray);
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    // margin: 0 auto;
  }
  &__del {
    position: absolute;
    cursor: pointer;
    right: 5px;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 10px;
      height: 10px;
      filter: invert(14%) sepia(79%) saturate(7166%) hue-rotate(357deg) brightness(108%)
        contrast(116%);
    }
  }
  &__label {
    position: absolute;
    // position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--gray);
    background: var(--white);
    font-size: 10px;
    padding: 0 7px;
    text-transform: lowercase;
    white-space: pre;
    // margin: 0 auto;
  }

  &__value {
    outline: none;
    padding: 10px;
    text-align: center;
    border: 1px solid var(--light-gray);
    // margin: 10px 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }

    &:active,
    &:focus {
      border: 1px solid var(--gold);
    }

    &::placeholder {
      font-size: 12px;
      color: var(--gray);
    }
  }
}

.disabled {
  cursor: default;
  pointer-events: none;
  color: var(--gray);

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
}
</style>
