<template>
  <div class="collection">
    <form class="collection__form" @submit.prevent="updateCollection">
      <div class="collection__line">
        <drop-down-select
          :width="240"
          :name="$t('collections.model')"
          :value="model.model"
          :options="model_list"
          @change="changeModel('model', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
        <text-input
          ref="name"
          :width="240"
          :name="$t('collections.name')"
          :placeholder="$t('collections.name')"
          :value="model.name"
          @change="changeInput('name', $event)"
        />
      </div>
      <div class="collection__line">
        <text-input
          ref="code"
          :width="240"
          :name="$t('collections.code')"
          :placeholder="$t('collections.code')"
          :value="model.code"
          @change="changeInput('code', $event)"
        />
        <number-input
          v-if="modelNumberWithIndividualSectionHeight.indexOf(model.model) > -1"
          :width="240"
          :name="$t('collections.min_distance_between_slats')"
          :placeholder="$t('collections.min_distance_between_slats')"
          :value="min_distance_between_slats"
          @change="changeInput('min_distance_between_slats', $event)"
        />
      </div>
      <div class="collection__box">
        <custom-checkbox
          v-if="model.model !== 5"
          v-model="model.perforation"
          :title="$t('collections.perforation')"
        />
        <custom-checkbox v-model="model.combining" :title="$t('collections.combining')" />
        <custom-checkbox
          v-if="modelNumberWithIndividualSectionHeight.indexOf(model.model) > -1"
          v-model="individual_section_height"
          :title="$t('collections.individual_section_height')"
        />
      </div>
      <div v-if="model.model === 3" class="collection__line">
        <number-input
          :width="350"
          :name="$t('collections.min_section_height')"
          :placeholder="$t('collections.min_section_height')"
          :value="min_section_height"
          @change="changeInput('min_section_height', $event)"
        />
      </div>
      <!-- скрытие -->
      <template v-if="model.model && model.model === 1">
        <div>
          <button
            type="button"
            class="collection__button"
            @click="toggleModal(true, hidePanels.type)"
          >
            <span>{{ $t('collections.hidePanels') }}</span>
            <icon-gear />
          </button>
        </div>

        <collection-modal v-if="modal.type && modal.type.value === 'hidePanels' && modal.modalOpen">
          <template v-slot:header>{{ $t('collections.hidePanels') }}</template>
          <template v-slot:body>
            <multiple-select
              :name="$t('collections.hidePanels')"
              :value="getSelectedHiding"
              :options="hidePanels.hidePanelsList"
              class="collection__col"
              @change="hidePanels.selectedHidePanels = $event"
            >
              <template v-slot:default="props">
                <div>{{ props.option.name }}</div>
              </template>
            </multiple-select>
            <table v-if="modal.type.value" class="collection-table">
              <tr v-for="item in getSelectedHiding" :key="item.id">
                <td>{{ item.name }}</td>
              </tr>
            </table>
          </template>
          <template v-slot:footer>
            <button class="button-submit" @click="toggleModal(false)">
              {{ $t('lk.back') }}
            </button>
          </template>
        </collection-modal>
      </template>
      <!-- материалы -->
      <template v-if="model.model">
        <div>
          <button
            type="button"
            class="collection__button"
            @click="toggleModal(true, materials.type)"
          >
            <span>{{ $t('collections.materials') }}</span>
            <icon-gear />
          </button>
        </div>

        <collection-modal v-if="modal.type && modal.type.value === 'materials' && modal.modalOpen">
          <template v-slot:header>{{ $t('collections.materials') }}</template>
          <template v-slot:body>
            <multiple-select
              :name="$t('collections.materials')"
              :value="materials.selectedMaterials"
              :options="materials.materialList"
              class="collection__col"
              @change="materials.selectedMaterials = $event"
            >
              <template v-slot:default="props">
                <div>{{ props.option.name }}</div>
              </template>
            </multiple-select>
            <table v-if="modal.type.value" class="collection-table">
              <draggable
                v-model="materials.selectedMaterials"
                group="people"
                :options="{ animation: 150 }"
                @start="drag = true"
                @end="drag = false"
              >
                <tr v-for="item in materials.selectedMaterials" :key="item.id">
                  <td>{{ item.name }}</td>
                </tr>
              </draggable>
            </table>
          </template>
          <template v-slot:footer>
            <button class="button-submit" @click="toggleModal(false)">
              {{ $t('lk.back') }}
            </button>
          </template>
        </collection-modal>
      </template>
      <!-- цвета -->
      <template v-if="model.model && colors.colorTypes">
        <div v-for="type of filteredColorsTypes" :key="type.value">
          <button type="button" class="collection__button" @click="toggleModal(true, type)">
            <span>{{ type.lang[$i18n.locale.slice(0, 2)] }}</span>
            <icon-gear />
          </button>
        </div>

        <collection-modal v-if="modal.type && hasColorType && modal.modalOpen">
          <template v-slot:header>{{ getTypeNameByLang }}</template>

          <template v-slot:body>
            <multiple-select
              :value="getSelectedColorsByType"
              :name="getTypeNameByLang"
              :options="getColorsByType(modal.type.value)"
              class="collection__col"
              @change="colors.selectedColors[modal.type.value] = $event"
            />
            <table v-if="modal.type.value" class="collection-table">
              <tr v-for="item in getSelectedColorsByType" :key="item.id">
                <td>{{ item.name }}</td>
              </tr>
            </table>
          </template>

          <template v-slot:footer>
            <button class="button-submit" @click="toggleModal(false)">
              {{ $t('lk.back') }}
            </button>
          </template>
        </collection-modal>
      </template>
      <div class="button-container">
        <button class="button-submit" type="button" @click="redirectToCollections()">
          {{ $t('lk.back') }}
        </button>
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import TextInput from '@/components/inputs/text-input/TextInput';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import CustomCheckbox from '@/components/inputs/custom-checkbox/CustomCheckbox';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import { errorHandler, modelNumberWithIndividualSectionHeight } from '@/mixins';
import models from '@/dictionary/models';
import types, { allTypes } from '@/components/admin-panel/color/types';
import MultipleSelect from '../../inputs/multiple-select/MultipleSelect.vue';
import IconGear from '@/components/ui/icons/IconGear.vue';
import CollectionModal from '@/components/admin-panel/collection/CollectionModal.vue';
import draggable from 'vuedraggable';

export default {
  name: 'Collection',
  components: {
    TextInput,
    NumberInput,
    CustomCheckbox,
    DropDownSelect,
    MultipleSelect,
    IconGear,
    CollectionModal,
    draggable
  },
  mixins: [errorHandler, modelNumberWithIndividualSectionHeight],
  data() {
    return {
      collection_id: this.$route.params.id,
      message: '',
      model: {},
      model_list: [],
      min_distance_between_slats: null,
      individual_section_height: false,
      min_section_height: null,
      materials: {
        materialList: [],
        selectedMaterials: [],
        type: {
          name: 'Материалы',
          value: 'materials'
        }
      },
      colors: {
        colorTypes: types,
        colorList: [],
        selectedColors: {
          track: [],
          rail: [],
          chain: [],
          fixed: []
        }
      },
      hidePanels: {
        hidePanelsList: [],
        selectedHidePanels: [],
        type: {
          name: 'Скрытие',
          value: 'hidePanels'
        }
      },
      modal: {
        type: null,
        modalOpen: false
      }
    };
  },
  computed: {
    ...mapState(['auth', 'user']),
    filteredColorsTypes() {
      return this.colors.colorTypes[this.model.model];
    },
    getSelectedColorsByType() {
      const selectedColors = this.colors.selectedColors[this.modal?.type?.value];
      const options = this.getColorsByType(this.modal.type.value);
      // проверяем чтобы значения которые есть, были доступны
      return this.getAvailableValue(selectedColors, options);
    },
    getSelectedHiding() {
      const { selectedHidePanels, hidePanelsList } = this.hidePanels;

      // проверяем чтобы значения которые есть, были доступны
      return this.getAvailableValue(selectedHidePanels, hidePanelsList);
    },
    hasColorType() {
      const result = allTypes.find((type) => type.value === this.modal.type.value);
      return result;
    },
    getTypeNameByLang() {
      return this.modal.type.lang[this.$i18n.locale.slice(0, 2)];
    }
  },
  mounted() {
    this.prepareModels();
    api
      .get('/materials')
      .then((res) => {
        this.materials.materialList = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
    api
      .get(`/collections/${this.collection_id}`)
      .then((res) => {
        if (res.error) {
          this.message = `Error ${res.error}`;
          return;
        }
        this.model = res.result;
        if (!this.model.properties) {
          this.model.properties = {};
        } else if (this.modelNumberWithIndividualSectionHeight.indexOf(this.model.model) > -1) {
          this.min_distance_between_slats = parseInt(
            this.model.properties.min_distance_between_slats,
            10
          );
          this.individual_section_height = this.model.properties.individual_section_height;
          if (typeof this.individual_section_height !== 'boolean') {
            // convert to bool
            this.individual_section_height = !!this.individual_section_height;
            this.model.properties.individual_section_height =
              !!this.model.properties.individual_section_height;
          }
        } else if (this.model.model === 3) {
          this.min_section_height = parseInt(this.model.properties.min_section_height, 10);
        }
        this.materials.selectedMaterials = this.model.material_list;
        this.hidePanels.selectedHidePanels = this.model.hiding || [];
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      })
      .then(() => {
        api
          .get('/colors')
          .then((res) => {
            this.colors.colorList = res.result;
            this.colors.selectedColors = this.idxToColors(this.model.colors);
          })
          .catch((error) => {
            this.message = `Error ${error}`;
          });
      })
      .then(() => {
        api
          .get('/hide-panels')
          .then((res) => {
            if (res && res.result.length) {
              console.log('res', res);
              this.hidePanels.hidePanelsList = res.result;
            }
          })
          .catch((error) => {
            this.message = `Error ${error}`;
          });
      });
  },
  methods: {
    getAvailableValue(selectedArr, optionsArr) {
      return selectedArr.flatMap((selected) =>
        optionsArr.filter((option) => option.id === selected.id)
      );
    },
    toggleModal(value, type = null) {
      this.modal.type = type;
      this.modal.modalOpen = value;
    },
    getColorsByType(type) {
      return this.colors.colorList.filter((color) => color.types.includes(type));
    },
    colorsToIdx(colorsObj) {
      // формируем индесы на основе цветов
      const colors = { ...colorsObj };

      for (const [name, arr] of Object.entries(colors)) {
        colors[name] = arr.map((item) => String(item.id));
      }

      return colors;
    },
    idxToColors(colorsObj) {
      // Формируем цвета на основе индексов
      const colorsIdxObj = { ...colorsObj };

      for (const [name, arr] of Object.entries(colorsIdxObj)) {
        const colors = this.colors.colorList.filter((color) => {
          if (arr.includes(String(color.id))) {
            return color;
          }
        });

        colorsIdxObj[name] = colors;
      }
      // чтобы не потерять реактивность, связываем со стартовым обьектом, главное сохранить начальные массивы внутри
      const assigned = Object.assign(this.colors.selectedColors, colorsIdxObj);

      return assigned;
    },
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
      if (key === 'name') {
        this.model.name = value;
      }
      if (key === 'code') {
        this.model.code = value;
      }
      if (key === 'min_distance_between_slats') {
        if (value) {
          this.model.properties.min_distance_between_slats = parseInt(value, 10);
          this.min_distance_between_slats = parseInt(value, 10);
        } else {
          this.model.properties.min_distance_between_slats = 0;
          this.min_distance_between_slats = 0;
        }
      }
      if (key === 'min_section_height') {
        if (value) {
          this.model.properties.min_section_height = parseInt(value, 10);
          this.min_section_height = parseInt(value, 10);
        } else {
          this.model.properties.min_section_height = 0;
          this.min_section_height = 0;
        }
      }
    },
    changeModel(key, value) {
      if (key === 'model') {
        this.model.model = value;
      }
    },
    prepareModels() {
      models.forEach((modelItem) => {
        this.model_list.push({
          value: modelItem.id,
          name: modelItem.name
        });
      });
    },
    updateCollection() {
      if (!this.validateForm()) {
        return;
      }

      const data = { ...this.model };
      data.materials = [];

      const colors = this.colorsToIdx(this.colors.selectedColors);
      data.colors = colors;

      data.hiding = this.hidePanels.selectedHidePanels;

      this.materials.selectedMaterials.forEach((material) => {
        data.materials.push(material.id);
      });

      if (!data.properties) {
        data.properties = {};
      }
      if (this.modelNumberWithIndividualSectionHeight.indexOf(data.model) > -1) {
        data.properties.individual_section_height = this.individual_section_height;
      }

      api
        .patch(`/collections/${this.collection_id}`, JSON.stringify(data))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          window.setTimeout(this.redirectToCollections, 1000);
          return this.messageHandler(this.$t('collections.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    validateForm() {
      this.message = '';
      if (!this.model.name) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('collections.name')}"`);
        this.$refs.name.focus();
        return false;
      }
      if (!this.model.code) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('collections.code')}"`);
        this.$refs.code.focus();
        return false;
      }
      if (!this.model.model) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('collections.model')}"`);
        this.$refs.model.focus();
        return false;
      }
      return true;
    },
    redirectToCollections() {
      window.location.href = `${window.location.origin}/collections`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 20px;
}

.delete-material-btn {
  border: 0;
}

.delete-material-btn:hover {
  cursor: pointer;
}

.collection {
  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__line {
    display: flex;
  }
  &__col {
    margin-bottom: 20px;
  }
}
</style>
