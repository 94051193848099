<template>
  <div class="currency">
    <div class="currency-form">
      <number-input
        :min="1"
        :width="190"
        :step="0.01"
        :value="exchange.EUR.value"
        name="EUR-RUB"
        @change="changeInput('EUR', $event)"
      />
      <number-input
        :min="1"
        :width="190"
        :step="0.01"
        :value="exchange.USD.value"
        name="EUR-USD"
        @change="changeInput('USD', $event)"
      />
    </div>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import { errorHandler } from '@/mixins';
import { dutchedVersion } from '@/config';

export default {
  name: 'Currency',
  components: { NumberInput },
  data() {
    return {
      exchange: {
        EUR: {
          value: '',
          id: ''
        },
        USD: {
          value: '',
          id: ''
        }
      },
      message: '',
      dutched: false
    };
  },
  mounted() {
    this.dutched = dutchedVersion;
    api
      .get('/currencies')
      .then((res) => {
        res.forEach((data) => {
          this.exchange = {
            ...this.exchange,
            [data.code]: {
              value: data.value,
              id: data.id
            }
          };
        });
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  computed: {
    ...mapState(['auth'])
  },
  mixins: [errorHandler],
  methods: {
    changeInput(key, value) {
      this.exchange[key].value = value.toFixed(2);
      this.messageHandler('');

      this.putCurrency(this.exchange[key].id, value);
    },
    putCurrency(id, value) {
      api
        .put(
          `/currencies/${id}`,
          JSON.stringify({
            value
          })
        )
        .then((res) => {
          if (res.message) return this.messageHandler(`Ошибка ${res.message}`);
          if (dutchedVersion) return this.messageHandler('Successfully saved ');
          return this.messageHandler('Данные успешно сохранены');
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    }
  }
};
</script>

<style lang="scss">
.currency-form {
  display: flex;
}
</style>
