<template>
  <div id="app">
    <router-view />
    <div class="version">{{ VERSION_DATA }}</div>
  </div>
</template>

<script>
import { date } from '@/../version.json';
import { mapState } from 'vuex';
import { api } from '@/api';
export default {
  name: 'App',
  computed: {
    ...mapState(['auth'])
  },
  components: {},
  created() {
    this.VERSION_DATA = date;
    const token = this.$route.query?.token;
    if (token) {
      this.$router.push(this.$route.path);
    }
  },
  async mounted() {
    await this.$store.dispatch('auth/checkInfoUser');
  }
};
</script>

<style lang="scss">
@import 'design';

#app {
  @extend %font-content;
  position: relative;
}

h1 {
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 2px;
  color: var(--gray);
}

h2 {
  font-size: 16px;
}
.version {
  position: absolute;
  bottom: 0px;
  right: 5px;
  opacity: 0.2;
  font-size: 8px;
  z-index: 999;
  &:hover {
    opacity: 1;
  }
}
</style>
