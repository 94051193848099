<template>
  <div class="materials-container">
    <table class="materials">
      <draggable
        v-model="materials"
        class="materials-drag"
        tag="div"
        v-bind="dragOptions"
        @start="drag = true"
        @end="dragEnd"
      >
        <transition-group class="materials" type="transition" :name="!drag ? 'flip-list' : null">
          <tr v-for="(item, index) in materials" :key="index" class="materials__item">
            <td class="materials__data">
              <h2>{{ item.name }}</h2>
            </td>
            <td>
              <router-link :to="`/material/${item.id}`">
                <img src="../../../assets/edit.svg" />
              </router-link>
            </td>
            <td>
              <router-link :to="`/material/delete/${item.id}`">
                <img src="../../../assets/close.svg" />
              </router-link>
            </td>
          </tr>
        </transition-group>
      </draggable>
    </table>

    <router-link class="material-add" to="/material/register">
      <button class="button-submit register-btn" type="submit">+ {{ $t('materials.add') }}</button>
    </router-link>
  </div>
</template>

<script>
import models from '@/dictionary/models';
import { api } from '@/api';
import { mapState } from 'vuex';
import { collections } from '@/config';
import draggable from 'vuedraggable';

export default {
  name: 'Coefficients',
  components: { draggable },
  data() {
    return {
      materials: [],
      message: '',
      drag: false
    };
  },
  created() {
    this.options = {
      collections,
      models
    };
  },
  computed: {
    ...mapState(['rate']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        filter: 'input',
        preventOnFilter: false
      };
    }
  },
  mounted() {
    api
      .get('/materials')
      .then((res) => {
        console.log('res', res);
        this.materials = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    viewFilters(key, value) {
      this[key] = value;
      if (this.getCoefficientsChange) {
        this.$store.dispatch('rate/getData', {
          model: this.model,
          collection: this.collection
        });
      }
    },
    dragEnd() {
      this.drag = false;
      this.$nextTick(() => {
        api
          .patch('/materials/order', JSON.stringify(this.materials.map((x) => x.id)))
          .then((res) => {
            if (res.message) return this.messageHandler(`Error ${res.message}`);
            console.log('res', res);
            window.setTimeout(this.redirectToMaterials, 1000);
            return this.messageHandler(this.$t('materials.saveded_successfully'));
          })
          .catch((error) => {
            this.message = `Error ${error}`;
          });
      });
    }
  }
};
</script>

<style lang="scss">
.materials {
  width: 100%;
  &-drag {
    display: flex;
  }
  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr 10% 10%;
  }
  &__data {
    display: flex;
    align-items: center;
  }

  &__info {
    color: var(--gray);
    font-size: 12px;
    margin-left: 10px;
  }
}

.register-btn {
  margin-top: 20px;
}
</style>
