<template>
  <div class="profile">
    <form class="profile__form" @submit.prevent="registerDistributor">
      <div class="profile__line">
        <p>{{ $t('distributor.partner.title') }}</p>
      </div>
      <div class="profile__line">
        <drop-down-select
          :width="420"
          :name="$t('distributor.partner.partner_type')"
          :value="partner_type"
          :options="[
            {
              value: 'jur-lico',
              name: $t('distributor.partner.entity')
            },
            {
              value: 'fiz-lico',
              name: $t('distributor.partner.physical_person')
            },
            {
              value: 'jur-lico-ne-rezident',
              name: $t('distributor.partner.legal_entity_non_resident')
            },
            {
              value: 'ip',
              name: $t('distributor.partner.individual_entrepreneur')
            }
          ]"
          @change="viewFilters('partner_type', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div class="profile__line">
        <div v-if="partner_type === 'jur-lico' || partner_type === 'jur-lico-ne-rezident'">
          <text-input
            ref="partner_name"
            :width="200"
            :name="$t('distributor.partner.name')"
            :placeholder="$t('distributor.partner.name')"
            :value="partner_name"
            @change="changeInput('partner_name', $event)"
          />
        </div>
        <div v-if="partner_type === 'jur-lico' || partner_type === 'jur-lico-ne-rezident'">
          <text-input
            ref="partner_name_full"
            :width="200"
            :name="$t('distributor.partner.name_full')"
            :placeholder="$t('distributor.partner.name_full')"
            :value="partner_name_full"
            @change="changeInput('partner_name_full', $event)"
          />
        </div>
        <div v-if="partner_type === 'ip'">
          <text-input
            ref="partner_name_international"
            :width="472"
            :name="$t('distributor.partner.name_international')"
            :placeholder="$t('distributor.partner.name_international')"
            :value="partner_name_international"
            @change="changeInput('partner_name_international', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <div
          v-if="partner_type === 'jur-lico' || partner_type === 'fiz-lico' || partner_type === 'ip'"
        >
          <number-input
            ref="partner_inn"
            :width="200"
            :name="$t('distributor.partner.inn')"
            :placeholder="$t('distributor.partner.inn')"
            :value="partner_inn"
            @change="changeInput('partner_inn', $event)"
          />
        </div>
        <div v-if="partner_type === 'jur-lico'">
          <number-input
            ref="partner_kpp"
            :width="200"
            :name="$t('distributor.partner.kpp')"
            :placeholder="$t('distributor.partner.kpp')"
            :value="partner_kpp"
            @change="changeInput('partner_kpp', $event)"
          />
        </div>
        <div v-if="partner_type === 'jur-lico'">
          <number-input
            ref="partner_kod_po_okpo"
            :width="200"
            :name="$t('distributor.partner.kod_po_okpo')"
            :placeholder="$t('distributor.partner.kod_po_okpo')"
            :value="partner_kod_po_okpo"
            @change="changeInput('partner_kod_po_okpo', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <div v-if="partner_type === 'jur-lico' || partner_type === 'ip'">
          <number-input
            ref="partner_ogrn"
            :width="200"
            :name="$t('distributor.partner.ogrn')"
            :placeholder="$t('distributor.partner.ogrn')"
            :value="partner_ogrn"
            @change="changeInput('partner_ogrn', $event)"
          />
        </div>
        <div
          v-if="
            partner_type === 'jur-lico' ||
            partner_type === 'jur-lico-ne-rezident' ||
            partner_type === 'ip'
          "
        >
          <text-input
            ref="partner_country_of_registration"
            :width="200"
            :name="$t('distributor.partner.country_of_registration')"
            :placeholder="$t('distributor.partner.country_of_registration')"
            :value="partner_country_of_registration"
            @change="changeInput('partner_country_of_registration', $event)"
          />
        </div>
        <div
          v-if="
            partner_type === 'jur-lico' ||
            partner_type === 'jur-lico-ne-rezident' ||
            partner_type === 'ip'
          "
        >
          <custom-masked-input
            ref="partner_registration_date"
            :width="200"
            :name="$t('distributor.partner.registration_date')"
            :placeholder="$t('distributor.date_mask')"
            :value="partner_registration_date"
            mask="11/11/1111"
            @change="changeInput('partner_registration_date', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <drop-down-select
          :width="420"
          :name="$t('distributor.partner.option_sending_e_check')"
          :value="partner_option_sending_e_check"
          :options="[
            {
              value: 'do-not-send',
              name: $t('distributor.partner.do_not_send')
            },
            {
              value: 'sms',
              name: $t('distributor.partner.sms')
            },
            {
              value: 'email',
              name: $t('distributor.partner.email')
            }
          ]"
          @change="viewFilters('partner_option_sending_e_check', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div class="profile__line">
        <p>{{ $t('distributor.physical_person.title') }}</p>
      </div>
      <div class="profile__line">
        <text-input
          :width="200"
          :name="$t('distributor.physical_person.fname')"
          :placeholder="$t('distributor.physical_person.fname')"
          :value="physical_person_fname"
          @change="changeInput('physical_person_fname', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.physical_person.sname')"
          :placeholder="$t('distributor.physical_person.sname')"
          :value="physical_person_sname"
          @change="changeInput('physical_person_sname', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.physical_person.patronymic')"
          :placeholder="$t('distributor.physical_person.patronymic')"
          :value="physical_person_patronymic"
          @change="changeInput('physical_person_patronymic', $event)"
        />
      </div>
      <div class="profile__line">
        <custom-masked-input
          :width="200"
          :name="$t('distributor.physical_person.birthday')"
          :placeholder="$t('distributor.date_mask')"
          :value="physical_person_birthday"
          mask="11/11/1111"
          @change="changeInput('physical_person_birthday', $event)"
        />
        <drop-down-select
          :width="200"
          :name="$t('distributor.gender.title')"
          :value="physical_person_sex"
          :options="[
            {
              value: 'male',
              name: $t('distributor.gender.male')
            },
            {
              value: 'female',
              name: $t('distributor.gender.female')
            }
          ]"
          @change="viewFilters('physical_person_sex', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div class="profile__line">
        <p>{{ $t('distributor.contact_person.title') }}</p>
      </div>
      <div class="profile__line">
        <text-input
          ref="contact_person_fname"
          :width="200"
          :name="$t('distributor.contact_person.fname')"
          :placeholder="$t('distributor.contact_person.fname')"
          :value="contact_person_fname"
          @change="changeInput('contact_person_fname', $event)"
        />
        <text-input
          ref="contact_person_sname"
          :width="200"
          :name="$t('distributor.contact_person.sname')"
          :placeholder="$t('distributor.contact_person.sname')"
          :value="contact_person_sname"
          @change="changeInput('contact_person_sname', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.contact_person.patronymic')"
          :placeholder="$t('distributor.contact_person.patronymic')"
          :value="contact_person_patronymic"
          @change="changeInput('contact_person_patronymic', $event)"
        />
      </div>
      <div class="profile__line">
        <text-input
          :width="200"
          :name="$t('distributor.contact_person.phone')"
          :placeholder="$t('distributor.contact_person.phone')"
          :value="contact_person_phone"
          @change="changeInput('contact_person_phone', $event)"
        />
        <text-input
          ref="contact_person_email"
          :width="200"
          :name="$t('distributor.contact_person.email')"
          type="email"
          :placeholder="$t('distributor.contact_person.email')"
          :value="contact_person_email"
          @change="changeInput('contact_person_email', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.contact_person.position')"
          :placeholder="$t('distributor.contact_person.position')"
          :value="contact_person_position"
          @change="changeInput('contact_person_position', $event)"
        />
      </div>
      <div class="profile__line">
        <p>{{ $t('distributor.bank_details.title') }}</p>
      </div>
      <div class="profile__line">
        <text-input
          :width="420"
          :name="$t('distributor.bank_details.account_number')"
          :placeholder="$t('distributor.bank_details.account_number')"
          :value="bank_details_account_number"
          @change="changeInput('bank_details_account_number', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.currency')"
          :placeholder="$t('distributor.bank_details.currency')"
          :value="bank_details_currency"
          @change="changeInput('bank_details_currency', $event)"
        />
      </div>
      <div class="profile__line">
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_name')"
          :placeholder="$t('distributor.bank_details.bank_name')"
          :value="bank_details_bank_name"
          @change="changeInput('bank_details_bank_name', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_bik')"
          :placeholder="$t('distributor.bank_details.bank_bik')"
          :value="bank_details_bank_bik"
          @change="changeInput('bank_details_bank_bik', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_current_account')"
          :placeholder="$t('distributor.bank_details.bank_current_account')"
          :value="bank_details_bank_current_account"
          @change="changeInput('bank_details_bank_current_account', $event)"
        />
      </div>
      <div class="profile__line">
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_country')"
          :placeholder="$t('distributor.bank_details.bank_country')"
          :value="bank_details_bank_country"
          @change="changeInput('bank_details_bank_country', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_city')"
          :placeholder="$t('distributor.bank_details.bank_city')"
          :value="bank_details_bank_city"
          @change="changeInput('bank_details_bank_city', $event)"
        />
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_address')"
          :placeholder="$t('distributor.bank_details.bank_address')"
          :value="bank_details_bank_address"
          @change="changeInput('bank_details_bank_address', $event)"
        />
      </div>
      <div class="profile__line">
        <text-input
          :width="200"
          :name="$t('distributor.bank_details.bank_phone')"
          :placeholder="$t('distributor.bank_details.bank_phone')"
          :value="bank_details_bank_phone"
          @change="changeInput('bank_details_bank_phone', $event)"
        />
        <text-input
          :width="420"
          :name="$t('distributor.bank_details.bank_swift')"
          :placeholder="$t('distributor.bank_details.bank_swift')"
          :value="bank_details_bank_swift"
          @change="changeInput('bank_details_bank_swift', $event)"
        />
      </div>
      <div class="profile__line">
        <p>{{ $t('distributor.contact_information.title') }}</p>
      </div>
      <div class="profile__line">
        <drop-down-select
          :width="420"
          :name="$t('distributor.contact_information.type.title')"
          :value="contact_information_type"
          :options="[
            {
              value: 'address',
              name: $t('distributor.contact_information.type.address')
            },
            {
              value: 'phone',
              name: $t('distributor.contact_information.type.phone')
            },
            {
              value: 'email',
              name: $t('distributor.contact_information.type.email')
            }
          ]"
          @change="viewFilters('contact_information_type', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div class="profile__line">
        <div v-if="contact_information_type === 'address' || contact_information_type === 'phone'">
          <drop-down-select
            :width="420"
            :name="$t('distributor.contact_information.category.title')"
            :value="contact_information_category"
            :options="contact_information_category_list"
            @change="viewFilters('contact_information_category', $event)"
          >
            <template v-slot:default="props">
              <div>{{ props.option.name }}</div>
            </template>
          </drop-down-select>
        </div>
      </div>
      <div class="profile__line">
        <div v-if="contact_information_type === 'phone'">
          <number-input
            :width="200"
            :name="$t('distributor.contact_information.type.phone')"
            :placeholder="$t('distributor.contact_information.type.phone')"
            :value="contact_information_phone"
            @change="changeInput('contact_information_phone', $event)"
          />
        </div>
        <div v-if="contact_information_type === 'email'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.type.email')"
            type="email"
            :placeholder="$t('distributor.contact_information.type.email')"
            :value="contact_information_email"
            @change="changeInput('contact_information_email', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <div v-if="contact_information_type === 'address'">
          <number-input
            :width="200"
            :name="$t('distributor.contact_information.index')"
            :placeholder="$t('distributor.contact_information.index')"
            :value="contact_information_index"
            @change="changeInput('contact_information_index', $event)"
          />
        </div>
        <div v-if="contact_information_type === 'address'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.country')"
            :placeholder="$t('distributor.contact_information.country')"
            :value="contact_information_country"
            @change="changeInput('contact_information_country', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <div v-if="contact_information_type === 'address'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.region')"
            :placeholder="$t('distributor.contact_information.region')"
            :value="contact_information_region"
            @change="changeInput('contact_information_region', $event)"
          />
        </div>
        <div v-if="contact_information_type === 'address'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.town')"
            :placeholder="$t('distributor.contact_information.town')"
            :value="contact_information_town"
            @change="changeInput('contact_information_town', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <div v-if="contact_information_type === 'address'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.street')"
            :placeholder="$t('distributor.contact_information.street')"
            :value="contact_information_street"
            @change="changeInput('contact_information_street', $event)"
          />
        </div>
        <div v-if="contact_information_type === 'address'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.building')"
            :placeholder="$t('distributor.contact_information.building')"
            :value="contact_information_building"
            @change="changeInput('contact_information_building', $event)"
          />
        </div>
        <div v-if="contact_information_type === 'address'">
          <text-input
            :width="200"
            :name="$t('distributor.contact_information.office_apartment')"
            :placeholder="$t('distributor.contact_information.office_apartment')"
            :value="contact_information_office_apartment"
            @change="changeInput('contact_information_office_apartment', $event)"
          />
        </div>
      </div>
      <div class="profile__line">
        <p>{{ $t('distributor.vat.title') }}</p>
      </div>
      <div class="profile__line">
        <drop-down-select
          :width="420"
          :name="$t('distributor.vat.title')"
          :value="vat_value"
          :options="vat_list"
          @change="viewFiltersVAT('vat_value', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
      </div>
      <div class="button-container">
        <button :disabled="checkRegister" class="button-submit" type="submit">
          {{ $t('lk.save') }}
        </button>
      </div>
      <div class="profile__line">
        <div v-if="new_distributor_hash !== ''">
          <text-input
            :width="640"
            name="link to activate a new distributor"
            placeholder="link to activate a new distributor"
            :value="new_distributor_hash"
          />
        </div>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import TextInput from '@/components/inputs/text-input/TextInput';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import CustomMaskedInput from '@/components/inputs/masked-input/CustomMaskedInput';
import { errorHandler } from '@/mixins';
import countries from '@/dictionary/countries';

export default {
  name: 'Distributor',
  components: {
    NumberInput,
    TextInput,
    DropDownSelect,
    CustomMaskedInput
  },
  mixins: [errorHandler],
  computed: {
    ...mapState(['auth', 'user']),
    checkRegister() {
      return this.register ? !this.email : false;
    }
  },
  watch: {},
  data() {
    return {
      message: '',
      partner_type: '',
      partner_name: '',
      partner_name_full: '',
      partner_name_international: '',
      partner_inn: '',
      partner_kpp: '',
      partner_kod_po_okpo: '',
      partner_ogrn: '',
      partner_country_of_registration: '',
      partner_registration_date: '',
      partner_option_sending_e_check: '',
      physical_person_fname: '',
      physical_person_sname: '',
      physical_person_patronymic: '',
      physical_person_birthday: '',
      physical_person_sex: '',
      physical_person_inn: '',
      contact_person_fname: '',
      contact_person_sname: '',
      contact_person_patronymic: '',
      contact_person_phone: '',
      contact_person_email: '',
      contact_person_position: '',
      bank_details_account_number: '',
      bank_details_currency: '',
      bank_details_bank_name: '',
      bank_details_bank_bik: '',
      bank_details_bank_current_account: '',
      bank_details_bank_country: '',
      bank_details_bank_city: '',
      bank_details_bank_address: '',
      bank_details_bank_phone: '',
      bank_details_bank_swift: '',
      contact_information_type: '',
      contact_information_category: '',
      contact_information_email: '',
      contact_information_phone: '',
      contact_information_index: '',
      contact_information_country: '',
      contact_information_region: '',
      contact_information_town: '',
      contact_information_street: '',
      contact_information_building: '',
      contact_information_office_apartment: '',
      contact_information_category_list: [],
      contact_information_category_address_list: [
        {
          value: 'actual',
          name: this.$t('distributor.contact_information.category.actual_address')
        },
        {
          value: 'legal',
          name: this.$t('distributor.contact_information.category.legal_address')
        }
      ],
      contact_information_category_phone_list: [
        {
          value: 'mobile',
          name: this.$t('distributor.contact_information.category.mobile_phone')
        },
        {
          value: 'work',
          name: this.$t('distributor.contact_information.category.work_phone')
        }
      ],
      new_distributor_hash: '',
      vat_value: '',
      vat_country: '',
      vat_percent: '',
      vat_list: [],
      countries: []
    };
  },
  mounted() {
    console.log('mounted distributor');
    this.prepareCountries();
    this.countries.forEach((value) => {
      this.vat_list.push({
        value: `${value.code}_${value.vat}`,
        name: `${value.name} ${value.vat}%`
      });
    });
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    registerDistributor() {
      console.log('registerDistributor');
      if (!this.validateForm()) {
        return;
      }
      api
        .post(
          '/registrations',
          JSON.stringify({
            email: this.contact_person_email,
            fname: this.contact_person_fname,
            sname: this.contact_person_sname,
            expireAt: '2045-09-02',
            userData: {
              enterprise: true,
              configurator: true,
              extendedData: {
                partner_type: this.partner_type,
                partner_name: this.partner_name,
                partner_name_full: this.partner_name_full,
                partner_name_international: this.partner_name_international,
                partner_inn: this.partner_inn,
                partner_kpp: this.partner_kpp,
                partner_kod_po_okpo: this.partner_kod_po_okpo,
                partner_ogrn: this.partner_ogrn,
                partner_country_of_registration: this.partner_country_of_registration,
                partner_registration_date: this.partner_registration_date,
                partner_option_sending_e_check: this.partner_option_sending_e_check,
                physical_person_fname: this.physical_person_fname,
                physical_person_sname: this.physical_person_sname,
                physical_person_patronymic: this.physical_person_patronymic,
                physical_person_birthday: this.physical_person_birthday,
                physical_person_sex: this.physical_person_sex,
                physical_person_inn: this.physical_person_inn,
                contact_person_fname: this.contact_person_fname,
                contact_person_sname: this.contact_person_sname,
                contact_person_patronymic: this.contact_person_patronymic,
                contact_person_phone: this.contact_person_phone,
                contact_person_email: this.contact_person_email,
                contact_person_position: this.contact_person_position,
                bank_details_account_number: this.bank_details_account_number,
                bank_details_currency: this.bank_details_currency,
                bank_details_bank_name: this.bank_details_bank_name,
                bank_details_bank_bik: this.bank_details_bank_bik,
                bank_details_bank_current_account: this.bank_details_bank_current_account,
                bank_details_bank_country: this.bank_details_bank_country,
                bank_details_bank_city: this.bank_details_bank_city,
                bank_details_bank_address: this.bank_details_bank_address,
                bank_details_bank_phone: this.bank_details_bank_phone,
                bank_details_bank_swift: this.bank_details_bank_swift,
                contact_information_type: this.contact_information_type,
                contact_information_category: this.contact_information_category,
                contact_information_email: this.contact_information_email,
                contact_information_phone: this.contact_information_phone,
                contact_information_index: this.contact_information_index,
                contact_information_country: this.contact_information_country,
                contact_information_region: this.contact_information_region,
                contact_information_town: this.contact_information_town,
                contact_information_street: this.contact_information_street,
                contact_information_building: this.contact_information_building,
                contact_information_office_apartment: this.contact_information_office_apartment,
                vat_country: this.vat_country.toUpperCase(),
                vat_value: this.vat_percent
              }
            }
          })
        )
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          console.log('res', res);
          this.new_distributor_hash = `${window.location.origin}/distributor/activate/${res.result.hash}`;
          return this.messageHandler(this.$t('distributor.created_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    viewFilters(key, value) {
      this[key] = value;

      if (key === 'contact_information_type' && this.contact_information_type === 'address') {
        this.contact_information_category_list = this.contact_information_category_address_list;
      } else if (key === 'contact_information_type' && this.contact_information_type === 'phone') {
        this.contact_information_category_list = this.contact_information_category_phone_list;
      }
    },
    viewFiltersVAT(key, value) {
      this[key] = value;
      const splitedValue = value.split('_');
      [this.vat_country, this.vat_percent] = splitedValue;
    },
    prepareCountries() {
      countries.forEach((countryItem) => {
        const localizedCountryName = this.$t(`countries.${countryItem.code}`);
        this.vat_list.push({
          value: `${countryItem.code}_${countryItem.vat}`,
          name: `${localizedCountryName} ${countryItem.vat}%`
        });
      });
    },
    validateForm() {
      console.log('validateForm');
      this.message = '';
      if (!this.partner_type) {
        this.messageHandler(
          `${this.$t('validate_required')} "${this.$t('distributor.partner.partner_type')}"`
        );
        return false;
      }
      if (this.partner_type === 'jur-lico') {
        if (!this.partner_name) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.name')}"`
          );
          this.$refs.partner_name.focus();
          return false;
        }
        if (!this.partner_name_full) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.name_full')}"`
          );
          this.$refs.partner_name_full.focus();
          return false;
        }
        if (!this.partner_inn) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.inn')}"`
          );
          this.$refs.partner_inn.focus();
          return false;
        }
        if (!this.partner_kpp) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.kpp')}"`
          );
          this.$refs.partner_kpp.focus();
          return false;
        }
        if (!this.partner_kod_po_okpo) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.kod_po_okpo')}"`
          );
          this.$refs.partner_kod_po_okpo.focus();
          return false;
        }
        if (!this.partner_ogrn) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.ogrn')}"`
          );
          this.$refs.partner_ogrn.focus();
          return false;
        }
        if (!this.partner_country_of_registration) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t(
              'distributor.partner.country_of_registration'
            )}"`
          );
          this.$refs.partner_country_of_registration.focus();
          return false;
        }
        if (!this.partner_registration_date) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.registration_date')}"`
          );
          // this.$refs.partner_registration_date.focus();
          return false;
        }
      } else if (this.partner_type === 'fiz-lico') {
        if (!this.partner_inn) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.inn')}"`
          );
          this.$refs.partner_inn.focus();
          return false;
        }
      } else if (this.partner_type === 'jur-lico-ne-rezident') {
        if (!this.partner_name) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.name')}"`
          );
          this.$refs.partner_name.focus();
          return false;
        }
        if (!this.partner_name_full) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.name_full')}"`
          );
          this.$refs.partner_name_full.focus();
          return false;
        }
        if (!this.partner_country_of_registration) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t(
              'distributor.partner.country_of_registration'
            )}"`
          );
          this.$refs.partner_country_of_registration.focus();
          return false;
        }
        if (!this.partner_registration_date) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.registration_date')}"`
          );
          // this.$refs.partner_registration_date.focus();
          return false;
        }
      } else if (this.partner_type === 'ip') {
        if (!this.partner_name_international) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.name_international')}"`
          );
          this.$refs.partner_name_international.focus();
          return false;
        }
        if (!this.partner_inn) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.inn')}"`
          );
          this.$refs.partner_inn.focus();
          return false;
        }
        if (!this.partner_ogrn) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.ogrn')}"`
          );
          this.$refs.partner_ogrn.focus();
          return false;
        }
        if (!this.partner_country_of_registration) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t(
              'distributor.partner.country_of_registration'
            )}"`
          );
          this.$refs.partner_country_of_registration.focus();
          return false;
        }
        if (!this.partner_registration_date) {
          this.messageHandler(
            `${this.$t('validate_required')} "${this.$t('distributor.partner.registration_date')}"`
          );
          // this.$refs.partner_registration_date.focus();
          return false;
        }
      }
      if (!this.contact_person_fname) {
        this.messageHandler(
          `${this.$t('validate_required')} "${this.$t('distributor.contact_person.fname')}"`
        );
        this.$refs.contact_person_fname.focus();
        return false;
      }
      if (!this.contact_person_sname) {
        this.messageHandler(
          `${this.$t('validate_required')} "${this.$t('distributor.contact_person.sname')}"`
        );
        this.$refs.contact_person_sname.focus();
        return false;
      }
      if (!this.contact_person_email) {
        this.messageHandler(
          `${this.$t('validate_required')} "${this.$t('distributor.contact_person.email')}"`
        );
        this.$refs.contact_person_email.focus();
        return false;
      }
      if (!this.vat_percent) {
        this.messageHandler(
          `${this.$t('validate_required')} "${this.$t('distributor.vat.title')}"`
        );
        return false;
      }
      console.log('validation OK');
      return true;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profile {
  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__line {
    display: flex;
  }
}
</style>
