<template>
  <form class="auth-form__body" @submit.prevent="save">
    <img alt="logo" src="../../assets/logo.svg" />
    <br />
    <h3 class="auth-form__title">Change Password</h3>
    <br />
    <div class="auth-form__input-container">
      <text-input
        id="password"
        class="auth-form__input"
        :width="250"
        :value="password"
        type="password"
        name="new password"
        @change="changePassword($event)"
      />
    </div>
    <br />
    <div class="auth-form__input-container">
      <text-input
        id="repeatPassword"
        class="auth-form__input"
        :width="250"
        :value="repeatPassword"
        type="password"
        name="repeat password"
        @change="changeRepeatPassword($event)"
      />
    </div>
    <br />
    <div class="auth-form__additional">
      <button class="button-submit auth-form__submit" type="submit">save changes</button>
    </div>
    <div class="error">{{ auth.commit }}</div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import TextInput from '@/components/inputs/text-input/TextInput';
import { api } from '@/api';
import { CONSTRUCTOR_URL, LIBRARY_URL } from '@/config';

export default {
  name: 'ResetPassword',
  components: {
    TextInput
  },
  data() {
    return {
      repeatPassword: '',
      password: ''
    };
  },
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    changeRepeatPassword(value) {
      this.repeatPassword = value;
    },
    changePassword(value) {
      this.password = value;
    },
    save() {
      const token = this.$route.query?.reset_token;
      if (!token) {
        this.$store.commit('auth/messageSuccess', 'Invalid token');
        return;
      }
      if (this.password !== this.repeatPassword) {
        this.$store.commit('auth/messageSuccess', 'Password mismatch');
        return;
      }
      this.$store.dispatch('auth/changePassword', {
        authData: { token, password: this.password },
        resolve: () => {
          this.$router.push(`/auth`);
        }
      });
    }
  }
};
</script>
