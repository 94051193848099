import axios from 'axios';
export class HttpClient {
  _client;
  _baseURL;

  constructor(baseURL) {
    this._baseURL = baseURL;
    const config = {
      baseURL,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    };
    this._client = axios.create(config);

    this._client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          error.response &&
          (error.response.status == 401) & (window.location.pathname != '/auth') &&
          window.location.pathname != '/forgot-password' &&
          window.location.pathname != '/reset-password'
        ) {
          window.location.href = `${window.location.origin}/auth`;
        }
        return Promise.reject(error);
      }
    );
  }

  setHeaders(cookie) {
    if (cookie) {
      this._client.defaults.headers.Cookie = cookie;
    }
  }

  setBearer(authToken) {
    this._client.defaults.headers.Authorization = `Bearer ${authToken}`;
  }

  clearBearer() {
    delete this._client.defaults.headers.Authorization;
  }

  getBaseUrl() {
    return this._baseURL;
  }

  async get(url, data = null, cookie = null) {
    console.log(`get cookie`, cookie);
    console.log(`get url`, url);
    return this._client.get(url, { params: data }).then((response) => response.data);
  }
  async post(url, data = null) {
    return this._client.post(url, data).then((response) => response.data);
  }
  put(url, data = null) {
    return this._client.put(url, data);
  }
  patch(url, data = null) {
    return this._client.patch(url, data);
  }
  delete(url, data = null) {
    return this._client.delete(url, { params: data });
  }
}

export default class FetchClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.headers = {
      Accept: 'application/json',
      withCredentials: true,
      credentials: 'include',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
    };
  }

  setBearer(authToken) {
    this.headers.Authorization = `Bearer ${authToken}`;
  }

  /**
   * @param {Response}
   */
  unauthRedirect(r) {
    if (
      r.status === 401 &&
      window.location.pathname != '/auth' &&
      window.location.pathname != '/forgot-password' &&
      window.location.pathname != '/reset-password'
    ) {
      window.location = '/auth';
    }
  }

  clearBearer() {
    delete this.headers.Authorization;
  }

  async fetch(method, url, body) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      headers: this.headers,
      method,
      body
    });

    this.unauthRedirect(response);

    return response.json();
  }

  async get(url) {
    return this.fetch('GET', url);
  }

  async post(url, body) {
    return this.fetch('POST', url, body);
  }

  async put(url, body) {
    return this.fetch('PUT', url, body);
  }

  async patch(url, body) {
    return this.fetch('PATCH', url, body);
  }

  async delete(url) {
    return this.fetch('DELETE', url);
  }
}
