<template>
  <div class="collections-container">
    <template v-if="collections.length">
      <table v-for="(item, index) in sortedCollections" :key="index" class="collections">
        <thead class="collections__head">
          {{
            getModelName(Number(index))
          }}
        </thead>
        <tr v-for="col in item" :key="col.id">
          <td>
            <span class="collections__info">
              {{ col.name }}
            </span>
          </td>
          <td>
            <router-link :to="`/collection/${col.id}`">
              <img src="../../../assets/edit.svg" />
            </router-link>
          </td>
          <td>
            <router-link :to="`/collection/delete/${col.id}`">
              <img src="../../../assets/close.svg" />
            </router-link>
          </td>
        </tr>
      </table>
    </template>
    <div v-else>Loading...</div>

    <router-link class="collection-add" to="/collection/register">
      <button class="button-submit register-btn" type="submit">+{{ $t('collections.add') }}</button>
    </router-link>
  </div>
</template>

<script>
import { api } from '@/api';
import { mapState } from 'vuex';

export default {
  name: 'Collections',
  components: {},
  data() {
    return {
      collections: [],
      message: ''
    };
  },
  computed: {
    ...mapState(['rate']),
    sortedCollections() {
      const orderedCollection = {};

      this.collections.forEach((col) => {
        const tmpValue = orderedCollection[col.model] || [];
        orderedCollection[col.model] = [...tmpValue, col];
      });

      return orderedCollection;
    }
  },
  mounted() {
    api
      .get('/collections')
      .then((res) => {
        console.log('res', res);
        this.collections = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    viewFilters(key, value) {
      this[key] = value;
      if (this.getCoefficientsChange) {
        this.$store.dispatch('rate/getData', {
          model: this.model,
          collection: this.collection
        });
      }
    },
    getModelName(modelKey) {
      let result = null;
      if (modelKey === 1) {
        result = 'Rollo';
      } else if (modelKey === 2) {
        result = 'Flow';
      } else if (modelKey === 3) {
        result = 'Panel';
      } else if (modelKey === 4) {
        result = 'Banner';
      } else if (modelKey === 5) {
        result = 'Slides';
      }
      return result;
    }
  }
};
</script>

<style lang="scss">
.collections {
  width: 100%;
  margin: 0px 0px 20px 0px;
  max-width: 300px;

  &__head {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 240%;
    color: var(--dark-gray);
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__info {
    color: var(--dark-gray);
    font-size: 14px;
    font-weight: 500;
  }
}

.register-btn {
  margin-top: 20px;
}
</style>
