<template>
  <div class="delete-material">
    <h1>{{ $t('delete_confirm') }}</h1>
    <div class="button-container">
      <button class="button-submit delete-btn" type="submit" @click="deleteMaterial">
        {{ $t('delete') }}
      </button>

      <router-link class="material-add" to="/materials">
        <button class="button-submit delete-btn" type="submit">
          {{ $t('cancel') }}
        </button>
      </router-link>
    </div>
    <div class="error">{{ this.error_message }}</div>
    <div class="success">{{ this.success_message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import { errorHandler } from '@/mixins';

export default {
  name: 'Material',
  components: {},
  mixins: [errorHandler],
  computed: {
    ...mapState(['auth', 'material'])
  },
  watch: {},
  data() {
    return {
      material_id: this.$route.params.id,
      error_message: '',
      success_message: ''
    };
  },

  methods: {
    deleteMaterial() {
      console.log('deleteMaterial', this.material_id);
      this.error_message = '';
      this.success_message = '';
      api
        .delete(`/materials/${this.material_id}`)
        .then((res) => {
          console.log('res', res);
          if (!res || res.error || res.message) {
            this.error_message = 'Failed to delete material';
            return;
          }
          this.success_message = this.$t('materials.deleted_successfully');
          window.setTimeout(this.redirectToMaterials, 1000);
        })
        .catch((error) => {
          console.error('error', error);
          this.error_message = 'Failed to delete material';
        });
    },
    redirectToMaterials() {
      console.log('redirectToMaterials');
      window.location.href = `${window.location.origin}/materials`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
}

.delete-btn:hover {
  cursor: pointer;
}

.delete-material {
  &__line {
    display: flex;
  }

  .error {
    margin-top: 20px;
    color: red;
  }

  .success {
    margin-top: 20px;
    color: green;
  }
}
</style>
