import cloneDeep from 'lodash-es/cloneDeep';
import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    coefficientsDefault: [],
    coefficients: [],
    showModal: false,
    error: false,
    commit: ''
  },
  mutations: {
    createCoefficient(state) {
      state.showModal = !state.showModal;
    },
    saveDataState(state, rateData) {
      state.coefficients.push(rateData);
    },
    getDataState(state, { coefficients }) {
      state.coefficients = coefficients;
      state.error = false;
    },
    getDataStateDefault(state, { coefficients }) {
      state.coefficientsDefault = coefficients;
    },
    error(state) {
      state.error = true;
    },
    editValue(state, id) {
      state.coefficients[id].edit = !state.coefficients[id].edit;
    },
    messageSuccess(state, msg) {
      state.commit = msg;
      setTimeout(() => {
        state.commit = '';
      }, 3000);
    },
    itemChangeValue(state, { value, index, id }) {
      state.coefficients[index].value = value;
    },
    itemChangeId(state, { index, id }) {
      state.coefficients[index].id = id;
    },
    itemDelete(state, item) {
      const copyState = cloneDeep(state.coefficients);
      const index = copyState.findIndex((el) => el.id === item.id);
      if (index > -1) copyState.splice(index, 1);
      const defIndex = state.coefficientsDefault.findIndex((coef) => coef.title === item.title);
      if (defIndex > -1)
        copyState.splice(index, 0, { ...state.coefficientsDefault[defIndex], id: null });
      state.coefficients = copyState;
    }
  },
  actions: {
    setValue({ commit }, id) {
      commit('editValue', id);
    },
    postData({ commit }, userData) {
      commit('itemChangeValue', {
        value: userData.value,
        index: userData.index
      });
      const data = cloneDeep(userData);
      delete data.index;

      api
        .post('/coefficients/', JSON.stringify(data))
        .then((res) => {
          commit('itemChangeId', { index: userData.index, id: res.id });
        })
        .catch((error) => {
          commit('error');
          console.log(error, 'error');
        });
    },
    putData({ commit }, { id, value, index }) {
      commit('itemChangeValue', {
        value,
        index
      });
      api
        .put(
          `/coefficients/${id}`,
          JSON.stringify({
            value
          })
        )
        .then((res) => {
          commit('itemChangeValue', {
            value: res.value,
            index
          });
          commit('messageSuccess', 'Успешно сохранено');
          // commit('editValue', index);
        })
        .catch((error) => {
          commit('error');
          console.log(error, 'error');
        });
    },
    deleteData({ commit, state }, { id, item }) {
      api
        .delete(`/coefficients/${id}`)
        .then(() => {
          commit('itemDelete', item);
        })
        .catch((error) => {
          commit('error');
          console.log(error, 'error');
        });
    },
    /*    postData({ commit }, { path, value }) {
      commit('postData', { path, value });
    }, */
    getData({ commit, state }, userData) {
      api
        .get(
          `/coefficients/?model=${userData.model}&collection=${userData.collection.toLowerCase()}${
            userData.materialId != null ? `&materialId=${userData.materialId}` : ''
          }`
        )
        .then((response) => {
          let coefficients;
          if (userData.materialId != null) {
            const materialCoefficients = new Map(response.map((c) => [c.title, c]));
            coefficients = cloneDeep(state.coefficientsDefault);
            for (let coefficient of coefficients) {
              const materialCoefficient = materialCoefficients.get(coefficient.title);
              coefficient.id = materialCoefficient != undefined ? +materialCoefficient.id : null;
              if (materialCoefficient) coefficient.value = materialCoefficient.value;
            }
            commit('getDataState', {
              coefficients
            });
          } else {
            commit('getDataState', {
              coefficients: cloneDeep(response)
            });
            commit('getDataStateDefault', {
              coefficients: cloneDeep(response)
            });
          }
        })
        .catch((error) => {
          console.log(error, 'error');
          commit('error');
        });
    }
  }
};
