import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    id: null,
    role: null,
    commit: ''
  },
  getters: {
    isAuth: ({ id }) => !!id
  },
  mutations: {
    login(state, userData) {
      state.id = userData.id;
      state.role = userData.role;
    },
    logout(state) {
      state.id = null;
      state.role = null;
    },
    checkInfoUser(state, info) {
      state.role = info.role;
      state.id = info.id;
    },
    messageSuccess(state, msg) {
      state.commit = msg;
      setTimeout(() => {
        state.commit = '';
      }, 3000);
    }
  },
  actions: {
    checkInfoUser({ commit, dispatch }) {
      api.get('/auth/token').then((res) => {
        // DG: надо было проверять что нам пришло в ответе
        if (res.id) {
          commit('checkInfoUser', res);
        } else {
          //commit('logout');
        }
      });
    },

    login({ commit }, authData) {
      api
        .post(
          '/auth/login',
          JSON.stringify({
            username: authData.username,
            password: authData.password
          })
        )
        .then((res) => {
          commit('login', {
            id: res.userId,
            role: res.role
          });
          if (res.statusCode) {
            throw new Error('Incorrect login or password');
          }
        })
        .catch((error) => {
          commit('messageSuccess', error);
        });
    },
    logout({ commit }) {
      commit('logout');
      api.post('/auth/logout', JSON.stringify({}));
    },
    sendEmail({ commit }, authData) {
      api
        .post(
          '/auth/forgotPassword',
          JSON.stringify({
            email: authData.email
          })
        )
        .then((res) => {
          if (res.statusCode) {
            throw new Error('Incorrect email');
          } else commit('messageSuccess', 'Confirmation email sent!');
        })
        .catch((error) => {
          commit('messageSuccess', error);
        });
    },
    changePassword({ commit }, { authData, resolve }) {
      api
        .post(
          '/auth/changePassword',
          JSON.stringify({
            token: authData.token,
            password: authData.password
          })
        )
        .then((res) => {
          if (res.statusCode) {
            throw new Error('Incorrect password');
          } else {
            commit('messageSuccess', 'Password updated');
            resolve();
          }
        })
        .catch((error) => {
          commit('messageSuccess', error);
        });
    }
  }
};
