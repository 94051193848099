<template>
  <div>
    <div v-show="!rate.showModal" class="coefficients">
      <div class="coefficients__filters">
        <div>
          <drop-down-select
            :width="170"
            :name="$t('coefficients.model')"
            :value="model"
            :options="options.models"
            @change="viewFilters('model', $event)"
          >
            <template v-slot:default="props">
              <div>{{ props.option.name }}</div>
            </template>
          </drop-down-select>
        </div>
        <div>
          <drop-down-select
            :width="170"
            :name="$t('coefficients.collection')"
            :value="collection"
            :options="getCollectionsByModel"
            @change="viewFilters('collection', $event)"
          >
            <template v-slot:default="props">
              <div>{{ props.option.name }}</div>
            </template>
          </drop-down-select>
        </div>
        <div>
          <drop-down-select
            :width="170"
            :name="$t('coefficients.material')"
            :value="materialId"
            :options="getMaterialsByModelAndCollection"
            @change="viewFilters('materialId', $event)"
          >
            <template v-slot:default="props">
              <div>{{ props.option.name }}</div>
            </template>
          </drop-down-select>
        </div>
      </div>
      <table v-show="getCoefficientsChange" class="coefficients__table">
        <tr v-show="model">
          <td class="coefficients__formula" colspan="4">
            {{ $t('coefficients.formula') }} = {{ getFormula() }}
          </td>
        </tr>
        <tr class="coefficients__value">
          <td
            v-for="(item, index) in this.rate.coefficients"
            v-show="showCoeffk(item)"
            :key="item.id"
          >
            <number-input
              :min="0"
              :step="0.01"
              :name="item.title"
              :value="item.value"
              :disabled="!!materialId && item.title != 'm1' && item.title != 'm2'"
              :isRemoved="materialId && item.id && (item.title == 'm1' || item.title == 'm2')"
              @change="saveCoefficients(item.id, item.title, index, $event)"
              @remove="removeCoefficients(item.id, item)"
            />
          </td>
        </tr>
        <tr>
          <td>
            <coefficient-description :model="this.model" />
          </td>
        </tr>
      </table>
      {{ rate.commit }}
    </div>
  </div>
</template>

<script>
import models from '@/dictionary/models';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import { api } from '@/api';
import { mapState } from 'vuex';
import CoefficientDescription from '@/components/admin-panel/CoefficientDescription';
import { dutchedVersion } from '@/config';

export default {
  name: 'Coefficients',
  components: { CoefficientDescription, NumberInput, DropDownSelect },
  data() {
    return {
      collection: '',
      model: '',
      materialId: null,
      formula: '',
      all_collections: [],
      options: {
        models,
        collections: []
      }
    };
  },
  computed: {
    ...mapState(['rate']),
    getCoefficientsChange() {
      return this.model && this.collection;
    },
    getCollectionsByModel() {
      if (this.model === 'slides') {
        return [
          {
            value: 'standart',
            name: 'Standart'
          }
        ];
      }

      const selectedModel = this.options.models.find((modelItem) => modelItem.value === this.model);
      const res = [];

      this.all_collections.forEach((collection) => {
        if (collection.model === selectedModel?.id) {
          res.push({
            value: collection.name.toLowerCase(),
            name: collection.name,
            combi: ['rollo', 'flow', 'banner', 'panel']
          });
        }
      });

      return res;
    },
    getMaterialsByModelAndCollection() {
      if (!this.mode && !this.collection) return [];
      const collection = this.all_collections.find(
        (collection) =>
          collection.name.toLowerCase() === this.collection &&
          collection.model === this.getModelKey(this.model)
      );
      const res = [];
      collection.material_list.forEach((material) => {
        res.push({
          value: material.id,
          name: material.name
        });
      });

      return res;
    }
  },
  mounted() {
    if (this.all_collections.length) return;

    api
      .get('/collections')
      .then((res) => {
        // console.log('this.options.collections', this.options.collections);
        this.all_collections = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    setCollection(value) {
      this.collection = value;
    },
    showCoeffk(item) {
      return dutchedVersion ? item.title !== 'k' : true;
    },
    getFormula() {
      let formula = '';
      switch (this.model) {
        case 'flow':
          formula = `(((W*H*m2) + (W+H)*m1) + Base) * k) * ${this.$t('lk.vat')}`;
          if (dutchedVersion) formula = `((W*H*m2) + (W+H)*m1) + Base) * ${this.$t('lk.vat')}`;
          break;
        case 'rollo':
          formula = `(((W*H)*m2 + (W+H)*m1) + Base) * k) * ${this.$t('lk.vat')}`;
          if (dutchedVersion) formula = `((W*H)*m2 + (W+H)*m1) + Base) * ${this.$t('lk.vat')}`;
          break;
        case 'panel':
          formula = `(((W*H*m2) + (W+H)*(m1*К. ${this.$t(
            'coefficients.count_section'
          )})) + Base) * k) * ${this.$t('lk.vat')}`;
          if (dutchedVersion)
            formula = `((W*H*m2) + (W+H)*(m1*К. ${this.$t(
              'coefficients.count_section'
            )})) + Base) * ${this.$t('lk.vat')}`;
          break;
        case 'banner':
          formula = `(((W*H*m2) + (W+H)*(m1*К. ${this.$t(
            'coefficients.count_section'
          )})) + Base) * k) * ${this.$t('lk.vat')}`;
          if (dutchedVersion)
            formula = `((W*H*m2) + (W+H)*(m1*К. ${this.$t(
              'coefficients.count_section'
            )})) + Base) * ${this.$t('lk.vat')}`;
          break;
        case 'slides':
          formula = `(((W*H)*m2 + (W+H)*m1) + Base) * k) * ${this.$t('lk.vat')}`;
          if (dutchedVersion) formula = `((W*H)*m2 + (W+H)*m1) + Base) * ${this.$t('lk.vat')}`;
          break;
        default:
          formula = '';
      }
      return formula;
    },
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    removeCoefficients(id, item) {
      this.$store.dispatch('rate/deleteData', { id, item });
    },
    saveCoefficients(id, key, index, value) {
      this[key] = value;
      if (id) this.$store.dispatch('rate/putData', { id, value, index });
      else {
        let data = {
          model: this.model,
          collection: this.collection,
          value: value,
          title: key,
          index: index
        };
        if (this.materialId) data.materialId = this.materialId;

        this.$store.dispatch('rate/postData', data);
      }
    },
    viewFilters(key, value) {
      // console.log('viewFilters', key, value);
      this[key] = value;
      if (key !== 'materialId') {
        this.rate.coefficients = [];
        this.materialId = null;
      }
      if (key === 'model') {
        this.collection = null;
      }
      if (this.getCoefficientsChange) {
        this.$store.dispatch('rate/getData', {
          model: this.model,
          collection: this.collection,
          materialId: this.materialId
        });
      }
    },
    getModelKey(modelName) {
      let result = null;
      if (modelName === 'rollo') {
        result = 1;
      } else if (modelName === 'flow') {
        result = 2;
      } else if (modelName === 'panel') {
        result = 3;
      } else if (modelName === 'banner') {
        result = 4;
      } else if (modelName === 'slides') {
        result = 5;
      }
      return result;
    }
  }
};
</script>

<style lang="scss">
.coefficients {
  &__value {
    display: flex;
    flex-wrap: wrap;
  }
  &__filters {
    display: flex;
  }
  &__formula {
    font-weight: 600;
  }
  &__table {
    tr {
      td {
        padding: 10px 0;
      }
    }
  }
}
</style>
