<template>
  <div class="auth-container">
    <div class="auth-form">
      <div class="auth-form__confirm">
        <Login v-if="componentName == ComponentType.Login" />
        <ResetPassword v-if="componentName == ComponentType.ResetPassword" />
        <ForgotPassword v-if="componentName == ComponentType.ForgotPassword" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Login from '@/components/authorization/Login';
import ForgotPassword from '@/components/authorization/ForgotPassword';
import ResetPassword from '@/components/authorization/ResetPassword';

import { api } from '@/api';
import { CONSTRUCTOR_URL, LIBRARY_URL } from '@/config';

const ComponentType = {
  Login: 'Login',
  ResetPassword: 'ResetPassword',
  ForgotPassword: 'ForgotPassword'
};

export default {
  name: 'Auth',
  components: {
    Login,
    ForgotPassword,
    ResetPassword
  },
  data() {
    return {
      ComponentType,
      componentName: ComponentType.Login,
      isLogin: true,
      host: location.host,
      fullPage: true
    };
  },
  computed: {
    ...mapState(['auth'])
  },
  watch: {
    'auth.id': {
      handler: function userRedirectHandler() {
        this.userRedirect();
      },
      deep: true
    },
    $route(val) {
      this.changeComponent(val);
    }
  },

  created() {
    this.$store.commit('auth/messageSuccess', '');
    this.CONSTRUCTOR_URL = CONSTRUCTOR_URL;
    this.LIBRARY_URL = LIBRARY_URL;
    this.changeComponent(this.$route);
    var self = this;
    let sh = function () {
      self.host = window.location.host;
      self.configuratorDomain = self.host;
    };
    sh();
    setTimeout(sh, 1000);
  },
  methods: {
    changeComponent(route) {
      if (route.name == 'forgot-password') this.componentName = ComponentType.ForgotPassword;
      if (route.name == 'reset-password') this.componentName = ComponentType.ResetPassword;
      if (route.name == 'auth') this.componentName = ComponentType.Login;
    },
    userRedirect() {
      if (this.$route.name == 'forgot-password' || this.$route.name == 'reset-password') return;
      api
        .get(`/users/${this.auth.id}`)
        .then((res) => {
          switch (true) {
            case res.configurator && res.library:
              this.$router.replace('/nav');
              break;
            case res.library:
              window.location.href = `${this.LIBRARY_URL}`;
              break;
            case res.configurator:
              window.location.href = `${this.CONSTRUCTOR_URL}`;
              break;
            default:
              this.$router.replace(`/profile/${this.auth.id}`);
          }
        })
        .catch((error) => {
          this.message = `Ошибка ${error}`;
        });
    }
  }
};
</script>

<style lang="scss">
.auth-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-image: url('./../../assets/bg.png');
  background-size: cover;
}

.auth-form {
  margin: 150px auto;
  width: 450px;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error {
      margin-top: 20px;
    }
  }
  &__submit {
    width: 100px;
  }

  &__label {
    position: absolute;
    // position: relative;
    top: -10px;
    left: 100px;
    color: gray;
    background: white;
    font-size: 10px;
    padding: 0 10px;
    text-transform: lowercase;
    margin: 0 auto;
  }

  &__confirm {
    background-color: #fff;
    padding: 50px;
  }

  &__title {
    color: #191919;
    padding-bottom: 20px;
  }

  &__input {
    border: none;
    outline: none;
    padding: 10px;
    text-align: center;
    // margin: 10px 0;
  }

  &__additional {
    display: flex;
    align-items: flex-end;
    width: 250px;
    margin: 0 20px 0 0;
    justify-content: space-between;
  }

  &__recover-passw {
    text-decoration: underline;
    font-size: 12px;
    border: none;
    cursor: pointer;
  }
}

.button-submit {
  background: #dabe96;
  height: 40px;
  width: max-content;
  padding: 10px;
  border: 0;

  font-size: 14px;

  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #ffffff;
  &:disabled {
    background: var(--light-gray);
  }
}
</style>
