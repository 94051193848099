const track = {
  value: 'track',
  lang: {
    ru: 'цвет скрытия / Track',
    en: 'Mechanism hiding color / Track'
  }
};
const rail = {
  value: 'rail',
  lang: {
    ru: 'цвет профиля',
    en: 'Profile color'
  }
};
const ribs = {
  value: 'rail',
  lang: {
    ru: 'цвет профиля',
    en: 'Ribs Color'
  }
};
const chain = {
  value: 'chain',
  lang: {
    ru: 'цвет цепочки',
    en: 'chain color'
  }
};
const fixed = {
  value: 'fixed',
  lang: {
    ru: 'цвет крепления',
    en: 'Fixing details color'
  }
};

export const allTypes = [track, rail, chain, fixed];

export default {
  1: [track, ribs, chain, fixed], // rollo
  2: [rail, fixed], // flow
  3: [track, ribs, fixed], // panel
  4: [ribs, fixed], // banner
  5: [track, fixed] // slides
};
