<template>
  <div class="collection">
    <form class="collection__form" @submit.prevent="registerCollection">
      <div class="collection__grid">
        <drop-down-select
          :name="$t('collections.model')"
          :value="model"
          :options="model_list"
          @change="viewFilters('model', $event)"
        >
          <template v-slot:default="props">
            <div>{{ props.option.name }}</div>
          </template>
        </drop-down-select>
        <text-input
          ref="name"
          :name="$t('collections.name')"
          :placeholder="$t('collections.name')"
          :value="name"
          @change="changeInput('name', $event)"
        />
        <text-input
          ref="code"
          :name="$t('collections.code')"
          :placeholder="$t('collections.code')"
          :value="code"
          @change="changeInput('code', $event)"
        />
        <number-input
          v-if="modelNumberWithIndividualSectionHeight.indexOf(model) > -1"
          :name="$t('collections.min_distance_between_slats')"
          :placeholder="$t('collections.min_distance_between_slats')"
          :value="min_distance_between_slats"
          @change="changeInput('min_distance_between_slats', $event)"
        />
      </div>
      <div v-if="model === 3" class="collection__line">
        <number-input
          :name="$t('collections.min_section_height')"
          :placeholder="$t('collections.min_section_height')"
          :value="min_section_height"
          @change="changeInput('min_section_height', $event)"
        />
      </div>
      <div class="collection__box">
        <custom-checkbox
          v-if="model.model !== 5"
          v-model="perforation"
          :title="$t('collections.perforation')"
        />
        <custom-checkbox v-model="combining" :title="$t('collections.combining')" />
        <custom-checkbox
          v-if="modelNumberWithIndividualSectionHeight.indexOf(model) > -1"
          v-model="individual_section_height"
          :title="$t('collections.individual_section_height')"
        />
      </div>
      <!-- скрытие -->
      <template v-if="model && model === 1">
        <div>
          <button
            type="button"
            class="collection__button"
            @click="toggleModal(true, hidePanels.type)"
          >
            <span>{{ $t('collections.hidePanels') }}</span>
            <icon-gear />
          </button>
        </div>

        <collection-modal v-if="modal.type && modal.type.value === 'hidePanels' && modal.modalOpen">
          <template v-slot:header>{{ $t('collections.hidePanels') }}</template>
          <template v-slot:body>
            <multiple-select
              :name="$t('collections.hidePanels')"
              :value="getSelectedHiding"
              :options="hidePanels.hidePanelsList"
              class="collection__col"
              @change="hidePanels.selectedHidePanels = $event"
            >
              <template v-slot:default="props">
                <div>{{ props.option.name }}</div>
              </template>
            </multiple-select>
            <table v-if="modal.type.value" class="collection-table">
              <tr v-for="item in getSelectedHiding" :key="item.id">
                <td>{{ item.name }}</td>
              </tr>
            </table>
          </template>
          <template v-slot:footer>
            <button class="button-submit" @click="toggleModal(false)">
              {{ $t('lk.save') }}
            </button>
          </template>
        </collection-modal>
      </template>
      <!-- материалы -->
      <template v-if="model">
        <div>
          <button
            type="button"
            class="collection__button"
            @click="toggleModal(true, materials.type)"
          >
            <span>{{ $t('collections.materials') }}</span>
            <icon-gear />
          </button>
        </div>

        <collection-modal v-if="modal.type && modal.type.value === 'materials' && modal.modalOpen">
          <template v-slot:header>{{ $t('collections.materials') }}</template>
          <template v-slot:body>
            <multiple-select
              :name="$t('collections.materials')"
              :value="materials.selectedMaterials"
              :options="materials.materialList"
              class="collection__col"
              @change="materials.selectedMaterials = $event"
            >
              <template v-slot:default="props">
                <div>{{ props.option.name }}</div>
              </template>
            </multiple-select>
            <table v-if="modal.type.value" class="collection-table">
              <tr v-for="item in materials.selectedMaterials" :key="item.id">
                <td>{{ item.name }}</td>
              </tr>
            </table>
          </template>
          <template v-slot:footer>
            <button class="button-submit" @click="toggleModal(false)">
              {{ $t('lk.save') }}
            </button>
          </template>
        </collection-modal>
      </template>
      <!-- цвета -->
      <template v-if="model && colors.colorTypes">
        <div v-for="type of filteredColorsTypes" :key="type.value">
          <button type="button" class="collection__button" @click="toggleModal(true, type)">
            <span>{{ type.lang[$i18n.locale.slice(0, 2)] }}</span>
            <icon-gear />
          </button>
        </div>
        <!-- передаем данные в модалку -->
        <collection-modal v-if="modal.type && hasColorType && modal.modalOpen">
          <template v-slot:header>{{ getTypeNameByLang }}</template>

          <template v-slot:body>
            <multiple-select
              :value="getSelectedColorsByType"
              :name="getTypeNameByLang"
              :options="getColorsByType(modal.type.value)"
              class="collection__col"
              @change="colors.selectedColors[modal.type.value] = $event"
            />
            <table v-if="modal.type.value" class="collection-table">
              <tr v-for="item in getSelectedColorsByType" :key="item.id">
                <td>{{ item.name }}</td>
              </tr>
            </table>
          </template>

          <template v-slot:footer>
            <button class="button-submit" @click="toggleModal(false)">
              {{ $t('lk.save') }}
            </button>
          </template>
        </collection-modal>
      </template>

      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import TextInput from '@/components/inputs/text-input/TextInput';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import CustomCheckbox from '@/components/inputs/custom-checkbox/CustomCheckbox';
import DropDownSelect from '@/components/inputs/drop-down-select/DropDownSelect';
import { errorHandler, modelNumberWithIndividualSectionHeight } from '@/mixins';
import models from '@/dictionary/models';
import types, { allTypes } from '@/components/admin-panel/color/types';
import IconGear from '../../ui/icons/IconGear.vue';
import MultipleSelect from '../../inputs/multiple-select/MultipleSelect.vue';
import CollectionModal from './CollectionModal.vue';

export default {
  name: 'CollectionRegister',
  components: {
    TextInput,
    NumberInput,
    CustomCheckbox,
    DropDownSelect,
    IconGear,
    MultipleSelect,
    CollectionModal
  },
  mixins: [errorHandler, modelNumberWithIndividualSectionHeight],
  data() {
    return {
      message: '',
      name: '',
      code: '',
      perforation: false,
      combining: false,
      model: null,
      model_list: [],
      min_distance_between_slats: 0,
      individual_section_height: false,
      min_section_height: 0,
      materials: {
        materialList: [],
        selectedMaterials: [],
        type: {
          name: 'Материалы',
          value: 'materials'
        }
      },
      colors: {
        colorTypes: types,
        colorList: [],
        selectedColors: {
          track: [],
          rail: [],
          chain: [],
          fixed: []
        }
      },
      hidePanels: {
        hidePanelsList: [],
        selectedHidePanels: [],
        type: {
          name: 'Скрытие',
          value: 'hidePanels'
        }
      },
      modal: {
        type: null,
        modalOpen: false
      }
    };
  },
  computed: {
    ...mapState(['auth', 'user']),
    filteredColorsTypes() {
      return this.colors.colorTypes[this.model];
    },
    getSelectedColorsByType() {
      const selectedColors = this.colors.selectedColors[this.modal?.type?.value];
      const options = this.getColorsByType(this.modal.type.value);
      // проверяем чтобы значения которые есть, были доступны
      return this.getAvailableValue(selectedColors, options);
    },
    getSelectedHiding() {
      const { selectedHidePanels, hidePanelsList } = this.hidePanels;

      // проверяем чтобы значения которые есть, были доступны
      return this.getAvailableValue(selectedHidePanels, hidePanelsList);
    },
    hasColorType() {
      const result = allTypes.find((type) => type.value === this.modal.type.value);
      return result;
    },
    getTypeNameByLang() {
      return this.modal.type.lang[this.$i18n.locale.slice(0, 2)];
    }
  },
  mounted() {
    this.prepareModels();
    api
      .get('/colors')
      .then((res) => {
        this.colors.colorList = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
    api
      .get('/materials')
      .then((res) => {
        this.materials.materialList = res.result;
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
    api
      .get('/hide-panels')
      .then((res) => {
        if (res) {
          this.hidePanels.hidePanelsList = res.result;
        }
      })
      .catch((error) => {
        this.message = `Error ${error}`;
      });
  },
  methods: {
    getAvailableValue(selectedArr, optionsArr) {
      return selectedArr.flatMap((selected) =>
        optionsArr.filter((option) => option.id === selected.id)
      );
    },
    colorsToIdx(colorsObj) {
      // формируем индесы на основе цветов
      const colors = { ...colorsObj };

      for (const [name, arr] of Object.entries(colors)) {
        colors[name] = arr.map((item) => String(item.id));
      }

      return colors;
    },
    toggleModal(value, type = null) {
      this.modal.type = type;
      this.modal.modalOpen = value;
    },
    getColorsByType(type) {
      return this.colors.colorList.filter((color) => color.types.includes(type));
    },
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    viewFilters(key, value) {
      console.log('viewFilters', key, value);
      this[key] = value;
    },
    prepareModels() {
      console.log('prepareModels');
      models.forEach((modelItem) => {
        this.model_list.push({
          value: modelItem.id,
          name: modelItem.name
        });
      });
    },
    registerCollection() {
      console.log('registerCollection');
      if (!this.validateForm()) {
        return;
      }
      const data = {
        name: this.name,
        code: this.code,
        translateds: [],
        active: true,
        materials: [],
        perforation: this.perforation,
        combining: this.combining,
        model: this.model
      };
      if (this.modelNumberWithIndividualSectionHeight.indexOf(this.model) > -1) {
        data.properties = {};
        data.properties.min_distance_between_slats = parseInt(this.min_distance_between_slats, 10);
        data.properties.individual_section_height = this.individual_section_height;
      }
      if (this.model === 3) {
        data.properties = {};
        data.properties.min_section_height = parseInt(this.min_section_height, 10);
      }

      const colors = this.colorsToIdx(this.colors.selectedColors);
      data.colors = colors;

      this.materials.selectedMaterials.forEach((material) => {
        data.materials.push(material.id);
      });

      data.hiding = this.hidePanels.selectedHidePanels;

      api
        .post('/collections', JSON.stringify(data))
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          console.log('res', res);
          window.setTimeout(this.redirectToCollections, 1000);
          return this.messageHandler(this.$t('collections.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    validateForm() {
      console.log('validateForm');
      this.message = '';
      if (!this.name) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('collections.name')}"`);
        this.$refs.name.focus();
        return false;
      }
      if (!this.code) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('collections.code')}"`);
        this.$refs.code.focus();
        return false;
      }
      if (!this.model) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('collections.model')}"`);
        this.$refs.model.focus();
        return false;
      }
      console.log('validation OK');
      return true;
    },
    redirectToCollections() {
      console.log('redirectToCollections');
      window.location.href = `${window.location.origin}/collections`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.collection {
  max-width: 500px;

  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 240px);
    gap: 20px;
    margin-bottom: 30px;

    .drop-down-select,
    .input__container {
      margin: 0;
      width: 100%;
    }
  }

  &__line {
    display: flex;
  }
  &__col {
    margin-bottom: 20px;
  }

  &__box {
    margin-bottom: 40px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 18px;
    cursor: pointer;
    border: none;
    span {
      font-size: 16px;
      font-weight: 500;
      color: var(--dark-gray);
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.collection-table {
  display: grid;
  row-gap: 10px;
  margin-bottom: 30px;
  td {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-gray);
  }
}
</style>
