<template>
  <div class="material">
    <form class="material__form" @submit.prevent="registerMaterial">
      <div class="material__line">
        <text-input
          ref="name"
          :width="350"
          :name="$t('materials.name')"
          :placeholder="$t('materials.name')"
          :value="name"
          @change="changeInput('name', $event)"
        />
        <text-input
          ref="code"
          :width="350"
          :name="$t('materials.code')"
          :placeholder="$t('materials.code')"
          :value="code"
          @change="changeInput('code', $event)"
        />
      </div>
      <div class="material__line">
        <number-input
          ref="maxWidth"
          :width="350"
          :name="$t('materials.maxWidth')"
          :placeholder="$t('materials.maxWidth')"
          :value="maxWidth"
          @change="changeInput('maxWidth', $event)"
        />
        <number-input
          ref="maxHeight"
          :width="350"
          :name="$t('materials.maxHeight')"
          :placeholder="$t('materials.maxHeight')"
          :value="maxHeight"
          @change="changeInput('maxHeight', $event)"
        />
      </div>
      <div class="material__line">
        <custom-checkbox v-model="perforation" :title="$t('materials.perforation')" />
        <custom-checkbox v-model="rotate" :title="$t('materials.rotate')" />
        <custom-checkbox v-model="stockAvailability" :title="$t('materials.stockAvailability')" />
      </div>
      <div class="button-container">
        <button class="button-submit" type="submit">{{ $t('lk.save') }}</button>
      </div>
    </form>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';
import TextInput from '@/components/inputs/text-input/TextInput';
import NumberInput from '@/components/inputs/number-input/NumberInput';
import CustomCheckbox from '@/components/inputs/custom-checkbox/CustomCheckbox';
import { errorHandler } from '@/mixins';

export default {
  name: 'Material',
  components: {
    TextInput,
    NumberInput,
    CustomCheckbox
  },
  mixins: [errorHandler],
  computed: {
    ...mapState(['auth', 'user'])
  },
  watch: {},
  data() {
    return {
      message: '',
      name: '',
      code: '',
      maxWidth: null,
      maxHeight: null,
      perforation: false,
      rotate: false,
      stockAvailability: true
    };
  },
  mounted() {
    console.log('mounted Material');
  },
  methods: {
    changeInput(key, value) {
      this[key] = value;
      this.message = '';
    },
    registerMaterial() {
      console.log('registerMaterial');
      if (!this.validateForm()) {
        return;
      }
      api
        .post(
          '/materials',
          JSON.stringify({
            name: this.name,
            code: this.code,
            translateds: [],
            active: true,
            perforation: this.perforation,
            rotate: this.rotate,
            stockAvailability: this.stockAvailability,
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight
          })
        )
        .then((res) => {
          if (res.message) return this.messageHandler(`Error ${res.message}`);
          console.log('res', res);
          window.setTimeout(this.redirectToMaterials, 1000);
          return this.messageHandler(this.$t('materials.saveded_successfully'));
        })
        .catch((error) => {
          this.messageHandler(`Error ${error}`);
        });
    },
    validateForm() {
      console.log('validateForm');
      this.message = '';
      if (!this.name) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('materials.name')}"`);
        this.$refs.name.focus();
        return false;
      }
      if (!this.code) {
        this.messageHandler(`${this.$t('validate_required')} "${this.$t('materials.code')}"`);
        this.$refs.code.focus();
        return false;
      }
      console.log('validation OK');
      return true;
    },
    redirectToMaterials() {
      console.log('redirectToMaterials');
      window.location.href = `${window.location.origin}/materials`;
    }
  }
};
</script>

<style lang="scss">
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.material {
  .message {
    padding-top: 20px;
  }

  &__form {
  }

  &__line {
    display: flex;
  }
}
</style>
